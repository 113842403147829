<template>
  <div>
    <r-header v-if="isLogin" :title="'个人中心'" :isL="true" :isR="true">
      <div slot="right" @click="out">
        <img src="../../assets/images/out.png" class="out-img">
      </div>
    </r-header>
    <r-header v-else :title="'个人中心'"></r-header>
    <van-loading v-if="loading" type="spinner" color="#1989fa" style="text-align:center;margin-top:2rem;" />
    <div class="container pd" v-show="!loading">
      <div class="flex column align-center" v-if="!isLogin">
        <p class="text-m marginTB">请先登录哦~</p>
        <van-button round  size="normal" type="info" class="mine-login-btn" @click="goLogin">去登录</van-button>
      </div>
      <div class="head-img" v-if="isLogin">
        <img
          src="https://img01.yzcdn.cn/vant/cat.jpeg"
          class="mine-avatar"
        />
        <div class="text">{{ username }}</div>
      </div>
      <van-grid :column-num="3" v-if="isLogin">
        <!-- <van-grid-item icon="user-circle-o" @click="myMessgae" text="个人信息" /> -->
        <!-- <van-grid-item icon="user-o" @click="getRealName" text="实名认证" /> -->
        <van-grid-item icon="user-o" @click="handleEditPwd" text="修改密码" />
        <van-grid-item icon="shop-o" @click="goRoute('SelftStore')" text="我的商铺" />
        <van-grid-item icon="home-o" @click="goRoute('SelftStall')" text="我的摊位" />
        <van-grid-item icon="cart-o" @click="goRoute('PayRecord')" text="交易记录" />
        <van-grid-item icon="balance-pay" @click="goRoute('Water')" text="水费充值" />
        <van-grid-item icon="balance-pay" @click="goRoute('Electric')" text="电费充值" />
      </van-grid>
    </div>
    <van-popup v-model="messagePop" position="bottom" :style="{ height: '40%' }" closeable>
      <p class="text-middle edit">{{ popTitle }}</p>
      <div>
        <van-form @submit="onSubmitBindPhone"  ref="form" :show-error-message="false">
          <!-- <van-field v-model="nicknameText"  type="tel" label="微信名" class="cell" /> -->
          <van-field
            size="large"
            v-model="phoneNumber"
            name="phoneNumber"
            type="digit"
            label="手机号"
            placeholder="请输入手机号"
            :rules="[{ required: true, message: '请输入正确的手机号', pattern }]"
            class="cell"
          />
          <!-- <van-field
            size="large"
            v-model="sms"
            type="digit"
            name="sms"
            label="短信验证码"
            placeholder="请输入短信验证码"
            :rules="[{ required: true, message: '短信验证码不能为空' }]"
            class="cell"
          >
            <template #button>
              <van-button size="small" type="primary" native-type="button" @click="sendCode">
                <van-button :disabled="!sendBtnshow" size="small" type="primary" native-type="button" @click="sendCode">
                  <span v-if="sendBtnshow">发送验证码</span>
                  <span v-else>重新发送{{ count > 0 ? `${count}s` : '' }}</span>
                </van-button>
              </van-button>
            </template>
          </van-field> -->
          <div style="margin: 16px;" v-show="!phoneText">
            <van-button round block type="info" native-type="submit">确认</van-button>
          </div>
        </van-form>
        <!-- <div class="p-80" v-show="nickname === '' || nickname === null || nickname === undefined">
          <van-button round block type="primary" @click="wxLogin">微信授权</van-button>
        </div> -->
      </div>
    </van-popup>
    <van-popup v-model="editPwdPop" position="bottom" :style="{ height: '50%' }" closeable @closed="handleClose">
      <p class="text-middle edit">修改密码</p>
      <van-form @submit="onSubmitEditPwd">
        <van-field
          size="large"
          v-model="oldPass"
          type="password"
          name="passwd"
          label="原密码"
          placeholder="请输入原密码"
          :rules="[{ required: true, message: '原密码不能为空/请输入数字或字母，长度不低于6位', pattern: pwdPattern }]"
          class="cell"
        />
        <van-field
          size="large"
          v-model="newPass"
          type="password"
          name="passwd"
          label="新密码"
          placeholder="请输入新密码"
          :rules="[{ required: true, message: '新密码不能为空/请输入数字或字母，长度不低于6位', pattern: pwdPattern }]"
          class="cell"
        />
        <van-field
          size="large"
          v-model="newPass2"
          type="password"
          name="passwd"
          label="确认密码"
          placeholder="请再次输入密码"
          :rules="[{ required: true, validator: checkPassword, message: '两次输入的密码不一致' }]"
          class="cell"
        />
        <div class="p-80" style="margin-top:0.8rem;">
          <van-button round block :loading="eidtPwdLoading" type="primary" native-type="submit">确定</van-button>
        </div>
      </van-form>
    </van-popup>
    <!-- <van-popup v-model="realNameOpen" position="bottom" :style="{ height: '40%' }" closeable>
      <p class="text-middle edit">实名信息完善</p>
      <div class="text-s mb-10" style="margin-left: 0.3rem;">注：请填写您本人真实姓名</div>
      <van-form @submit="setRealName" ref="realform" :show-error-message="false">
        <van-field
          size="large"
          v-model="realName"
          name="realName"
          label="姓名"
          placeholder="请输入您的真实姓名"
          :rules="[{ required: true, message: '请输入您的真实姓名' }]"
          class="cell"
        />
        <div class="p-80" style="margin-top:0.8rem;">
          <van-button round block :loading="realNameLoading" type="primary" native-type="submit">确定</van-button>
        </div>
      </van-form>
    </van-popup> -->
  </div>
</template>

<script>
import RHeader from '@/components/RHeader.vue'
import { mapMutations } from 'vuex'
export default {
  components: {
    RHeader
  },
  data () {
    return {
      loading: false,
      disabled: false,
      messagePop: false,
      phoneNumber: '',
      sms: '',
      timer: null,
      count: '',
      sendBtnshow: true,
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      // pwdPattern: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$](?=.*\d))(.{6,})$/,
      pwdPattern: /^[A-Za-z\d@$!%*?&]{6,16}$/,
      isLogin: false,
      phoneText: '',
      nicknameText: '',
      realNameOpen: false,
      realName: '',
      realNameLoading: false,
      editPwdPop: false,
      eidtPwdLoading: false,
      // showPassword: false,
      // pwdType: 'password',
      oldPass: '',
      newPass: '',
      newPass2: ''
    }
  },
  computed: {
    // isLogin () {
    //   return localStorage.getItem('Authorization')
    // },
    username () {
      return this.$store.getters.getUsername
    },
    phone () {
      return this.$store.getters.getPhone
    },
    nickname () {
      return this.$store.getters.getNickName
    },
    dialogtype () {
      return (this.phone !== '' && this.phone !== null) && (this.nickname !== '' && this.nickname !== null)
    },
    popTitle () {
      // if (this.$store.getters.getPhone === '') {
      //   return '绑定手机号'
      // } else if (this.$store.getters.getNickName === '') {
      //   return '微信授权'
      // } else {
      // }
      return '个人信息'
    }
  },
  created () {
    // 我的页面 url有code值，就走接口把code传递给后端
    const code = this.getUrlCode('code')
    const state = this.getUrlCode('state')
    if (code) {
      if (state) {
        this.servieLogin({ code, state })
      } else {
        this.servieLogin({ code }) // 后台登录
      }
    } else {
      this.isLogin = !!localStorage.getItem('Authorization')
    }
  },
  methods: {
    ...mapMutations(['saveToken', 'saveUsername', 'savePhone', 'saveNickName']),
    handleEditPwd () {
      this.editPwdPop = true
    },
    checkPassword (val) {
      return val === this.newPass
    },
    handleShowPwd () {
      this.showPassword = !this.showPassword
      if (this.showPassword) {
        this.pwdType = this.showPassword ? 'text' : 'password'
      }
    },
    onSubmitEditPwd () {
      this.eidtPwdLoading = true
      this.$axios.post('/gw/updatepassword', {
        phone: this.phone,
        oldPass: this.oldPass,
        newPass: this.newPass,
        newPass2: this.newPass2
      }).then(res => {
        this.eidtPwdLoading = false
        const { code } = res.data
        if (code === 200) {
          this.$toast({
            type: 'success',
            message: '修改成功'
          })
          this.editPwdPop = false
          this.oldPass = ''
          this.newPass = ''
          this.newPass2 = ''
        } else {
          this.$toast({
            type: 'fail',
            message: res.data.msg
          })
        }
      })
    },
    // 截取地址栏code
    getUrlCode (name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [null, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    handleClose () {
      this.oldPass = ''
      this.newPass = ''
      this.newPass2 = ''
    },
    servieLogin (data) {
      // alert(code)
      const { state } = data
      this.loading = true
      this.$axios.post('/gw/wxcode', data).then(res => {
        this.loading = false
        // alert(JSON.stringify(res.data))
        const data = JSON.parse(JSON.stringify(res.data))
        const { code, token, username, nickname, phone } = data
        if (code === 200) {
          this.saveToken({ Authorization: token })
          this.saveUsername({ username: username })
          this.savePhone(phone)
          this.saveNickName(nickname)
          this.phoneText = phone
          this.nicknameText = nickname
          this.isLogin = !!localStorage.getItem('Authorization')
          if (state) {
            this.$toast({
              type: 'success',
              message: '绑定成功'
            })
          } else {
            this.$toast({
              type: 'success',
              message: '登录成功'
            })
          }
          this.$router.replace({
            name: 'mine'
          })
        } else {
          this.loading = false
          this.$toast({
            type: 'fail',
            message: res.data.msg
          })
        }
      })
    },
    out () {
      this.$dialog.confirm({
        title: '提示',
        message: '将退出账号？'
      }).then(() => {
        this.afresh('已退出！', 'success')
      }).catch(() => {
        // on cancel
      })
    },
    goLogin () {
      this.$router.push({
        name: 'login'
      })
    },
    onSubmitBindPhone () {
      this.$axios.post('/gw/bindphone', {
        phone: this.phoneNumber
        // smsmsg: this.sms
      }).then(res => {
        const { data, code } = res.data
        if (code === 200) {
          this.savePhone(data.phone)
          this.saveNickName(data.nickname)
          this.phoneText = data.phone
          this.phoneNumber = data.phone
          this.nicknameText = data.nickname
          this.$dialog.alert({
            title: '提示',
            message: '手机号绑定成功，如果需要手机号登录，初始密码为123456'
          }).then(() => {})
          this.messagePop = false
        } else {
          this.$toast({
            type: 'fail',
            message: res.data.msg
          })
        }
      })
    },
    myMessgae () {
      this.messagePop = true
      this.$axios.post('/gw/personinfo').then(res => {
        const { nickname, phone } = res.data
        this.savePhone(phone)
        this.saveNickName(nickname)
        this.phoneText = phone
        this.nicknameText = nickname
        if (phone) {
          this.phoneNumber = phone
        }
      })
    },
    goRoute (val) {
      this.$router.push({
        name: val
      })
    },
    isWXBrowser () {
      return (
        String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
        'micromessenger'
      )
    },
    wxLogin () {
      const isWXBrowser = this.isWXBrowser()
      if (isWXBrowser) {
        const code = this.getUrlCode('code')
        if (!code) {
          this.getWeChatCode() // 微信授权
        }
      }
    },
    // 访问微信地址，用来获取code
    getWeChatCode () {
      // let local = encodeURIComponent(window.location.href); // 获取当前页面地址作为回调地址
      const local = 'http://www.whwwsc.com/mine' // 获取当前页面地址作为回调地址
      const appid = 'wx63c208bb195251ca'
      // 通过微信官方接口获取code之后，会重新刷新设置的回调地址【redirect_uri】
      window.location.href =
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=${this.phone}#wechat_redirect`
    },
    sendCode () {
      this.$refs.form.validate('phoneNumber').then(valid => {
        if (!valid) {
          this.$axios.post('/gw/sendmsg', {
            phone: this.phoneNumber
          }).then(res => {
            const data = res.data
            if (data.code === 200) {
              this.sendBtn()
            } else {
              this.$toast({
                type: 'fail',
                message: res.data.msg
              })
            }
          }).catch((err) => {
            this.$toast({
              type: 'fail',
              message: err
            })
          })
        }
      })
    },
    getRealName () {
      this.realNameOpen = true
      this.$axios.get('/gw/userrealname').then(res => {
        const data = res.data
        if (data.code === 200) {
          this.realName = data.data
        }
      })
    },
    setRealName () {
      this.realNameLoading = true
      this.$refs.realform.validate('realName').then(valid => {
        if (!valid) {
          this.$axios.post('/gw/updateuserrealname', {
            realname: this.realName
          }).then(res => {
            const data = res.data
            if (data.code === 200) {
              this.realNameLoading = false
              this.realNameOpen = false
              this.realName = ''
              this.$toast({
                type: 'success',
                message: '已成功！'
              })
            } else {
              this.$toast({
                type: 'fail',
                message: res.data.msg
              })
            }
          }).catch((err) => {
            this.$toast({
              type: 'fail',
              message: err
            })
          })
        } else {
          this.realNameLoading = false
        }
      })
    },
    sendBtn () {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.sendBtnshow = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.sendBtnshow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.marginTB{
  margin-top:100px;
  margin-bottom: 20px;
}
.mine-login-btn{
  padding: 0 80px!important;
}
.edit{
  padding: 20px 0;
  text-align: center;
}
.head-img{
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mine-avatar{
  margin-top: 30px;
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
  border-radius: 50px;
  align-self: center;
  margin-bottom: 10px;
}
.text{
  font-size: 24px!important;
  text-align: center;
}
.van-card__title{
  font-size: 16px;
}
.storebox{
  width: 90px;
  height: 90px;
  line-height: 90px;
  border-radius: 5px;
  color: #fff;
  font-size: 40px;
  text-align: center;
}
.out-img{
  width: 28px;
  height: 28px;
}
.dialog{
  position: absolute;
  width: 250px;
  height: 200px;
  padding-top: 20px;
  left: 22%;
  font-size: 18px;
  background: #fff;
  z-index: 100;
  text-align: center;
}
.dialog .success {
  display: block;
  background: coral;
  padding: 5px;
}
.dialog .fail{
  background: #eee;
  padding: 5px;
}
.mask{
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #000;
  opacity: 0.3;
  z-index: 98;
}
.cell {
  ::v-deep.van-field__label {
    width: 80px;
  }
}
</style>
